@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');



path:focus {
  outline: none;
  border: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: montserrate;
}

html {
  scroll-behavior: smooth;
}


.header{
  /* background-color: red; */
  width: 92%;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  align-items: center;
}

/* Sticky header class */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #009EE3;
  transition: background-color 0.8s ease; /* Add a smooth transition for the background color */
  z-index: 10000000;
}

.sticky-nav{
  position: fixed;
  top: 0;
  width: 100%;
  /* z-index: 100; */
  background-color: #009EE3;
  transition: background-color 0.8s ease; /* Add a smooth transition for the background color */
  z-index: 10000000;
}


.secondContainer{
  /* top: -60px; */
  /* background-color: red; */
  height: 55vh;
  position: relative;
  z-index: 100;
  width: 90%;
  margin: 0px auto;
  /* overflow-x: scroll;
  overflow-y: scroll; */
}

@media (max-width: 800px) {
  .secondContainer {
    height: 100%;
    margin-top: 40px;
  }
  .main {
    /* Add padding/margin equal to the height of your fixed header */
    margin-top: 150px;
  }
}

.mainHero {
  position: relative; /* Establish a stacking context */
}

.hero {
  position: relative; /* Adjust position if needed */
  width: 90%;
  margin: 0 auto;
  z-index: 20; /* Adjust z-index as needed to appear above firstStrip */
}

.firstStrip {
  /* bottom: 10px; */
  margin-top: -50px;
  position: absolute; /* Adjust position if needed */
  width: 100%;
  height: 104px;
  background: linear-gradient(180deg, #E8F9FD 0%, rgba(232, 249, 253, 0.00) 100%);
  /* background-color: red; */
  z-index: -1; /* Adjust z-index as needed to appear behind hero */
}


@media (max-width: 800px) {
  .firstStrip {
    /* bottom: 10px; */
    margin-top: 10px;
  }

  .heroRight {
    max-width: 80%;
    margin: 0px auto;
  
  }

  .heroRight img{
    max-width: 50%;
    margin: 0px auto;
    
  }
}



.hero h1{
  /* font-family: Montserrat; */
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: normal;
}




.heroHeader{
  color: #000;
/* font-family: Montserrat; */
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 363.249px;
height: 40.347px;
flex-shrink: 0;
margin-top: 20px;
}

.heroIcons{
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
  text-align: center;
}

.heroIcons p{
color: #000;
text-align: center;
/* font-family: Montserrat; */
font-size: 15px;
font-style: normal;
font-weight: 900;
line-height: normal;
width: 222.513px;
height: 17.858px;
flex-shrink: 0;
}

.heroRight{
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  align-items: left;
  justify-content: center;
  border: 2px solid #E8F9FD;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 30px 20px 50px #E8F9FD;
  background-color: white;

}

.heroRight div{
  text-align: center;
  padding: 30px!important;
  


}

.heroRight div p{
  /* width: 362.63px;
height: 51px; */
flex-shrink: 0;
color: #000;
text-align: center;
/* font-family: Montserrat; */
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 134.4%; 

}

.heroRight div h2{
  color: #000;
/* font-family: Montserrat; */
font-size: 16px;
font-style: normal;
font-weight: 700;
margin-top: -10px;
}

.heroRight div a{
  width: 318.292px;
/* height: 41.851px; */
/* flex-shrink: 0; */
border-radius: 11px;
background: #009EE3;
border: none;
color: #FFF;
padding: 10px;
text-align: center;
/* font-family: Montserrat; */
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 134.4%; /* 21.504px */

}

/* .heroRight img{
  background-color: pink;

} */

.heroRight img {
  height: 250px;
  filter: drop-shadow(0px 0px 50px #99e0ff); /* Initial drop shadow with no vertical offset */
  animation: moveUpDown 4s linear infinite;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
    filter: drop-shadow(0px 100px 50px #99e0ff); /* Initial drop shadow */
  }
  50% {
    transform: translateY(-15px);
    filter: drop-shadow(0px 0px 100px #99e0ff); /* Animated drop shadow with vertical offset */
  }
}




.legends {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: first baseline;
  /* background-color: #000; */
  /* height: 100%; */
  /* margin-top: 100px; */
  margin-left: 50px;
  margin-top: 150px;
}

.legends .legend {
  display: flex; 
  /* display: inline-flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: -50px; */
  margin-top: -16px;
}

.legends .legend .color {
  /* background-color: rebeccapurple!imp; */
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-right: 3px;
}




.svgDiv{
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: row;
}


.hovered-link {
  background-color: #009EE3;
  color: white!important;
}


/* Styles for the secondary header (tabs) */
.secondaryHeader {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0px auto;
  /* margin-top: 17px; */
  /* width: 90%; */
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  /* margin-bottom: 10px; */
  /* z-index: 100000000000000000000000000000000000000000000; */
}

.secondaryHeader li {
  color: #006592;
  padding: 3px 15px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

/* Active tab styles */
.secondaryHeader li.active-tab {
  background-color: #006592;
  color: white;
}



@media (max-width: 800px) {
  .secondaryHeader li {
    color: #006592;
    padding: 3px 15px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
}
/* ---------GP Profile---------- */

.GPProfile{
  /* background-color: red; */
  margin-top: 80px!important;
}
.GPProfile .iconsAndTexts {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.secondColumn{
  /* background-color: rebeccapurple; */
  display: flex;
  align-items: center;
  padding-left: 50px;
  /* justify-content: center; */
}


@media (max-width: 800px) {
  .secondColumn{
    /* background-color: pink; */
    display: flex;
    align-items: center;
  padding-left: 15px;

    /* justify-content: center; */
  }
}

.GPProfile .iconsAndTexts .texts{
  /* background-color: red; */
}

.GPProfile .iconsAndTexts .texts h1{
  color: #000;
  /* SH1 */
  /* font-family: Inter; */
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 146.523%; /* 36.631px */
}


  .GPProfile .iconsAndTexts .texts p{
    margin-top: -11px!important;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 104.023%;
  }


  .LandUse{
    display: flex;
    align-items: center;
    /* background-color: palegoldenrod; */
    box-shadow: 10px 9px 21px -9px rgba(0, 0, 0, 0.25);
border-radius: 10px;
padding: 10px;
  }

  .LandUse h1{
    /* background-color: rebeccapurple; */
    /* font-family: Inter; */
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: 146.523%;
  }



  .climateVariability .iconAndText{
  display: flex;
}


.climateVariability .iconAndText .texts h1{
  /* font-family: Inter; */
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 146.523%; /* 36.631px */
  
}


.climateVariability .iconAndText .texts p{
/* Sh2 */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 104.023%; /* 16.644px */
  
}





/* Add your Bootstrap styles here */

.sliding-window {
  overflow: hidden;
  position: relative;
  max-width: 500px; /* Adjust the maximum width as needed */
  margin: 0 auto;
}

.tabs-container {
  transition: transform 0.3s ease-in-out;
  display: flex;
  width: 100%;
}

.tab-pane {
  flex: 1;
}

/* Adjust the width and margin to control the sliding effect */
.tab-content {
  display: flex;
  transform: translateX(-0%);
  transition: transform 0.3s ease-in-out;
}


.heroIcons .interalIcon{
  transition: transform 0.8s ease ;
  
}

.heroIcons .interalIcon:hover {
  /* border: 2px solid red; */
  transform: scale(0.9);
  cursor: pointer;
}

.heroIcons .p2 {
  display: none;
  text-align: center;
  /* border-radius: 5px; */
  color: black;
  transition: display 0.8s ease;
}

.heroIcons .interalIcon:hover .p2 {
  display: block!important;
}

.heroIcons .interalIcon:hover .p1 {
  display: none!important;
}


.homeIcon :hover{

 transform: scale(1.05);
 transition: all 0.2s ease-in-out;
}